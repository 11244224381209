<template>
  <Header :values="values" :header="header"></Header>
  <div v-if="loading" class="h-1 w-1">
    <a-loader color="gray_color"/>
  </div>
  <div v-else>
    <div v-if="user.isRestaurant" class="padding-header">
      <section class="px-4 py-2 text-left space-y-2 border-b border-gray">
        <div class="flex-auto flex items-start">
          <div class="flex-2 left-0 mr-1" style="min-width: 72px">
            <a-initials :name="values?.name" :src="values?.profile_picture" :desing-class="'custom-width'"></a-initials>

          </div>
          <div class="flex-auto px-1">
            <div class="flex">
              <div class="flex font-light items-center">
                <i class="icon-heartEmpty pr-2" />
                <p class="text-lg text-black">{{ values?.name }}</p>
              </div>
            </div>
            <div v-if="values.ig_username" class="flex font-light items-center">
              <i class="icon-instagram pr-2" />
              <p>{{ ` @${values?.ig_username}`}}</p>
            </div>
            <div v-if="values.phone" class="flex font-light items-center">
              <i class="icon-phone pr-2" />
              <p>{{ ` ${values?.phone}`}}</p>
            </div>
            <Rating v-model="values.rating" :stars="5" :cancel="false" :readonly="true"/>
          </div>
          <div class="flex-none text-primary flex-shrink-0">
            <router-link :to="{ name: 'profile.user.edit' }" class="block font-bold border rounded-full py-2 px-8 font-medium bg-primary text-white text-center">
              Editar
            </router-link>
          </div>
        </div>
        <div v-if="values?.address" class="text-lg txt_addres break-words">
          <i class="fas fa-map-marker-alt pr-1" />
          <p class="inline-block">{{ values.address }}</p>
          <p v-if="values.city" class="inline-block">{{ `, ${values.zip_code} ${values.city}` }}</p>
          <p v-if="values.state" class="inline-block">{{ `, ${values.state}` }}</p>
        </div>
        <p class="text-sm break-words pb-sm">
          {{ values?.biography }}
        </p>
      </section>
      <div class="p-normal flex flex-col justify-center mt-32">
        <div class="btn__container">
          <a :href="`https://instagram.com/${values.ig_username}`" class="btn" target="_blank">
            <i class="fab fa-instagram"></i>
            <span class="text-xl">Ver en instagram</span>
          </a>
        </div>
      </div>
    </div>
    <!-- FOODIE PROFILE -->
    <div v-else class="pt-16">
      <div class="p-normal border-b border-gray">
        <div class="flex">
          <div class="flex-auto flex flex-col text-left">
<!--            <div v-if="values.profile_picture !== null" class="flex-1">-->
<!--              <img :src="values.profile_picture" alt="foodie" class="user-profile-image rounded-full h-24 w-24"/>-->
<!--            </div>-->
<!--            <div v-else class="flex-1">-->
<!--              <img src="../../assets/logo.png" alt="default" class="user-profile-image rounded-full h-24 w-24"/>-->
<!--            </div>-->
            <a-initials :name="values?.name" :src="values?.profile_picture" :desing-class="'custom-width'"></a-initials>

            <div class="flex-1 flex flex-col text-left py-small">
              <!--            <p class="text-xl font-normal">{{ values.name }}</p>-->
              <!--            <p v-if="values?.ig_username" class="font-light">-->
              <span class="flex items-center text-xl">
              <i class="icon-heartEmpty pr-2"></i>
              <p>{{ values?.name }}</p>
            </span>
              <span v-if="values?.ig_username" class="flex items-center">
              <i class="icon-instagram text-xl pr-2"></i>
              <p class="text-lg"><a :href="`https://instagram.com/${values?.ig_username}`" class="text-primary cursor-pointer" target="_blank">{{ ` @${values?.ig_username}` }}</a></p>
            </span>
              <!--            <small class="flex text-xs text-gray items-center">-->
              <!--              <i class="fas fa-map-marker-alt pr-2" />-->
              <!--              {{ values?.address ? values?.address : '&#45;&#45; Sin direción &#45;&#45;'  }}-->
              <!--            </small>-->
            </div>
            <div class="flex-1">
              <p class="text-gray pr-3 align-bottom">{{ values?.biography }}</p>
            </div>
          </div>
          <div class="flex-none flex flex-col text-center space-b justify-between">
            <div class="block border-b border-gray">
              <h4 class="text-xl">{{ values.ig_followers_count ? formattedFollowers(values.ig_followers_count) : 0 }}</h4>
              <h4 class="text-gray">Followers</h4>
            </div>
            <div class="block border-b border-gray">
              <h4 class="text-xl">{{ values.completed_collabs_count ? values.completed_collabs_count : 0 }}</h4>
              <h4 class="text-gray">Colabs</h4>
            </div>
            <div class="block">
              <h4 class="text-xl">{{ values.ig_engagement_prc ? values.ig_engagement_prc : 0 }}%</h4>
              <h4 class="text-gray">Engagement</h4>
            </div>
            <!--router-link :to="{ name: 'profile.user.edit' }" class="border rounded-full  p-2 font-medium bg-primary text-white">
              Editar perfil
            </router-link-->
            <router-link :to="{ name: 'profile.user.edit' }" class="block font-bold border rounded-full w-full mt-2 py-2 px-8 uppercase font-medium bg-primary text-white">
              Editar
            </router-link>
<!--            <button class="block font-bold border rounded-full w-full mt-2 py-2 px-8 uppercase font-medium bg-primary text-white">-->
<!--              Editar-->
<!--            </button>-->
          </div>
        </div>
      </div>

<!--      <div  class="p-normal flex flex-col justify-center mt-32">-->
<!--        <div class="btn__container">-->
<!--          <button class="border border-red px-8 py-4 rounded-md text-xl">Agregar</button>-->
<!--        </div>-->
<!--      </div>-->
      <div v-if="values.is_premium && products" class="flex flex-col items-center mx-2 mt-4">
        <div v-for="product in products" :key="product" class="flex border rounded-md py-4 px-4 justify-between mb-2 w-full xl:w-2/4">
          <div class="text-left mr-2 text-sm lg:text-base">{{ product.description }}</div>
          <div class="bold min-w-8 text-right" style="min-width: 3.5rem; font-weight: 600;">{{ $filters.currencyFormatter(product.price_e2 / 100) + '€' }}</div>
        </div>
      </div>
      <div v-else class="p-normal flex flex-col justify-center mt-32">
        <div class="btn__container">
          <a :href="`https://instagram.com/${values.ig_username}`" class="btn" target="_blank">
            <i class="fab fa-instagram"></i>
            <span class="text-xl">Ver en instagram</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Header from '@/components/authenticated/Header'
import Rating from 'primevue/rating'
// import axios from 'axios'

export default {
  name: 'Show',
  components: {
    Header,
    Rating
  },
  data () {
    return {
      values: [],
      igImages: [],
      loading: true,
      products: [],
      header: {
        type: 'profile.show'
      },
      body: {
        attach: [],
        detach: []
      },
      image: {
        fail: false
      },
      FBError: false,
      FBCError: '',
      FBConnected: false,
      isFetching: true,
      defaultplaceholder: require('@/assets/placeholde_images.svg')
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    ...mapActions({
      showFoodie: 'foodies/show',
      showRestaurant: 'restaurants/show',
      facebookConnect: 'session/facebookConnect'
    }),
    formattedFollowers (followers) {
      if (followers >= 1000000) {
        return parseFloat(followers / 1000000).toFixed(1) + 'M'
      } else if (followers >= 1100) {
        return parseFloat(followers / 1000).toFixed(1) + 'k'
      }
      return followers
    },
    getFoodieProfile (id) {
      this.$repository.foodies
        .show(id, { with_count: 'completedCollabs', with: 'user' })
        .then(({ data: response }) => (this.values = response))
        .finally(() => {
          // window.FB.getLoginStatus((response) => {
          //   if (response.status === 'connected') {
          //     this.FBConnected = true
          //     axios
          //       .get(`https://graph.facebook.com/v11.0/${this.values.user.ig_user_id}/media?access_token=${response.authResponse.accessToken}&fields=media_type,media_url`)
          //       .then(({ data }) => {
          //         // console.log(medias?.data?.data)
          //         this.igImages = data.data.filter(item => item.media_type === 'IMAGE')
          //       })
          //       .catch((err) => (console.log(err)))
          //   } else {
          //     this.FBConnected = false
          //     console.log('disconected:', response)
          //   }
          // })
          this.loading = !this.loading
        })
    },
    getRestaurantProfile (id) {
      this.$repository.restaurants
        .show(id, { with_count: 'completedCollabs', with: 'user' })
        .then(({ data: response }) => (this.values = response))
        .finally(() => {
          // window.FB.getLoginStatus((response) => {
          //   if (response.status === 'connected') {
          //     this.FBConnected = true
          //     axios
          //       .get(`https://graph.facebook.com/v11.0/${this.values.user.ig_user_id}/media?access_token=${response.authResponse.accessToken}&fields=media_type,media_url`)
          //       .then(({ data }) => {
          //         // console.log(medias?.data?.data)
          //         this.igImages = data.data.filter(item => item.media_type === 'IMAGE')
          //       })
          //       .catch((err) => (console.log(err)))
          //   } else {
          //     this.FBConnected = false
          //     console.log('disconected:', response)
          //   }
          // })
          this.loading = !this.loading
        })
    },
    getProfileValues () {
      this.user.isRestaurant ? this.getRestaurantProfile(this.user.restaurant.id) : this.getFoodieProfile(this.user.foodie.id)
      if (this.user.isFoodie && this.user.foodie.is_premium) {
        this.$repository.foodies.products(this.user.foodie.id).index().then(({ data }) => {
          this.products = data.data
        })
      }
    },
    async login () {
      this.$facebook.login(response => {
        const { authResponse, status } = response

        if (status === 'connected') {
          this.facebookConnect({
            body: {
              access_token: authResponse.accessToken,
              fb_user_id: authResponse.userID
            }
            // configuration: {
            //   customHeaders: {
            //     Authorization: `Bearer ${localStorage.getItem('accesstoken')}`
            //   }
            // }
          })
            .then(() => {
              this.FBConnected = true
              this.$router.go()
            })
            .catch(({ response }) => {
              if (response?.data?.error_code) {
                this.FBError = true
                switch (response?.data?.error_code) {
                  case 'FB001': {
                    this.FBCError = ''
                    break
                  }
                  case 'FB002': {
                    this.FBCError = 'Debe asignar todos los permisos para poder utilizar la plataforma'
                    break
                  }
                  case 'FB003': {
                    this.FBCError = 'No se econtró ninguna página de facebook'
                    break
                  }
                  case 'FB004': {
                    this.FBCError = 'El nombre de usuario de instagram ya ha sido usado'
                    break
                  }
                  case 'FB005': {
                    this.FBCError = 'La pagina de facebook seleccionada no posee ninguna cuenta de Instagram Business'
                    break
                  }
                  case 'FB006': {
                    this.FBCError = 'No se consigue el email'
                    break
                  }
                  case 'FB007': {
                    this.FBCError = 'No existe una pagina para ese usuario de instagram '
                    break
                  }
                  default: {
                    this.FBCError = 'No se pudo conectar.'
                    break
                  }
                }
              }
            })
        }
      }, {
        scope: 'public_profile,email,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement'
      })
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      window.scrollTo({ top: 0 })
      this.getProfileValues()
    }
  }
}

</script>

<style lang="scss" scoped>
.user-profile-image {
  width: 80px !important;
  height: 80px !important;
  object-fit: cover;
}

.grid-button {
  padding: 10px 32px;
}

.make_galery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1em;
}

.make_galery{
  display: inline-block;
  width: calc( 100% - 5px);
  text-align: left;
  .galery_grid{
    display:inline-block;
    width: calc(33% - 5px);
    position: relative;
    height: 0px;
    padding-top: 32.341%;
    background-size: cover !important;
    margin-right: 5px;
    background-position: 50% 50% !important;
  }
  .placeholder_img{
    display: block;
    position: relative;
    height: calc(100vh - 333px);
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}
/*
.make_galery{
  display: inline-block;
  width: calc( 100% - 5px);
  text-align: left;
  margin-left: 5px;
  .galery_grid{
    display:inline-block;
    width: calc(33% - 5px);
    position: relative;
    height: 28vh;
    background-size: cover !important;
    margin-right: 5px;
    background-position: 50% 50% !important;
  }
}*/
.grid-gallery {
  display: grid;
  grid-template-columns: repeat(3, minmax(6rem, 1fr));
}

.grid-gallery > div {
  display: grid;
}

.grid-gallery > div::before {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.grid-gallery > div::before,
.grid-gallery > div > img {
  grid-area: 1 / 1 / 1 / 1;
}

.grid-gallery > div > img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
.custom-width {
  min-width: 72px;
  min-height: 72px;
  width: 72px;
  height: 72px;
}
.custom-margin{
  margin-top: 45%;
}
/////
.btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  min-width: 110px;
  background: linear-gradient(to right, #ff3019 0%,#c90477 100%);
  padding: 1rem 2rem;
  text-decoration: none;
  color: #fff;
  display: flex;
  transition: all .2s ease-in-out;
  margin-right: 10px;

  i {
    color: #fff;
    font-size: 20px;
    padding-right: 10px;
    transition: all .3s ease-in-out;
  }
  span {
    font-family: "Roboto", sans-serif;
    align-self: center;
    transform: translateX(0px);
    transition: all .1s ease-in-out;
    opacity: 1;
  }
  //
  //&:hover {
  //  transform: scale(1.1);
  //  background: linear-gradient(to right, #ff3019 0%,#c90477 100%);
  //  box-shadow: 0px 10px 30px rgba(0,0,0,0.10);
  //  i {
  //    transform: translateX(45px);
  //    padding-right: 0;
  //    color: #FFF;
  //  }
  //  span {
  //    transform: translateX(30px);
  //    opacity: 0;
  //  }
  //}
  //&:active {
  //  transform: scale(1);
  //  box-shadow: 0px 2px 10px rgba(0,0,0,0.10);
  //}
}
</style>
